:root {
  --ws-color-slonovaca: #FFF9F7;
  --ws-color-hvar: #61dafb;
  --ws-color-night: #0D0140;
  --ws-color-sternenhimmel: #130160;
  --ws-color-levanda: #D6CDFE;
}

body {
  margin: 0;
  font-family: 'DM Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--ws-color-slonovaca);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--ws-color-hvar);
}
