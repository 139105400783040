.info-panel-header {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 0.5px solid #dee1e7;
  padding-bottom: 1.25rem;
  display: flex;
  align-items: center;
}

.info-panel-content {
  margin-top: 1.25rem;
}

.into-panel {
  background-color: white;
  margin: 1.5rem;
  padding: 1.25rem;
  border-radius: 1rem;
}

.info-panel-header-icon {
  margin-right: 0.75rem;
  color: #ff9228;
}
