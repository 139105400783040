.back-nav-header {
  padding: 1.25rem;
  padding-bottom: 0.5rem;
}

.back-nav-header > a {
  width: 100vw;
  color: black;
}

.heading-title {
  font-size: 22px;
  line-height: 27px;
  color: #0d0140;
  font-weight: 700;
}
