.booking-intro {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.calendar-booking-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
}

.calendar-booking-time-container {
  display: flex;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
}

.time-selection-container {
  margin: 1.5rem;
}

.calendar-booking-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.calendar-day-taken {
  background-color: var(--ws-color-slonovaca);
  color: gray;
}

.calendar-day-action {
  background-color: #ddeffb;
  color: #3b5369;
}

.booking-input-item {
  padding-top: 1.5rem;
}

.date-selection-container {
  margin-bottom: 7.5rem;
  background-color: white;
  padding: 1.25rem;
  border-radius: 1rem;
}
