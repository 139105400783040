.action-header-bar {
    position: fixed;
    right: 16px;
    top: 16px;
    z-index: 4;
    padding: 8px;
    border-radius: 16px;
    background-color: #ffffff6b;
}

.action-header-bar:hover {
    background-color: #c1c1c16b;
    cursor: pointer;
}