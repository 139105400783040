.inline-multi {
  padding: 1.5rem;
  overflow: hidden;
}
.inline-multi-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0d0140;
  margin-bottom: 0.75rem;
}
.inline-multi-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: white;
  overflow-y: auto;
}

.inline-multi-container > .inline-multi-item:last-child {
  margin-right: 0.75rem;
}

.inline-multi-item {
  padding: 0.75rem;
  border-radius: 1rem;
  min-width: 2.3125rem;
  font-weight: 700;
  text-align: center;
}

.inline-multi-selected {
  background: #fca34d;
  color: white;
}
