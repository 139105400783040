.agreement-container {
  margin: 1.5rem;
}

.agreement-checkbox-container {
  display: flex;
  align-items: baseline;
  position: relative;
  top: -8px;
}

.agreement-content-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
}
