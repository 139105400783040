.onboarding-qr-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.onboarding-qr-button {
  cursor: pointer;
  padding: 0.875rem;
  max-width: 20rem;
  border-radius: 1rem;
  /* height: 4.6rem; */
  background-color: #efedf7;
  /* border: 1px dashed #9d97b5; */
  color: black;

  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.onboarding-qr-button:hover {
  background-color: #bdb3e6;
}

.qr-image-container {
  margin-right: 0.875rem;
}

.qr-content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.qr-content-container-line1 {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0.375rem;
}

.qr-content-container-line2 {
  font-size: 10px;
  line-height: 13px;
  color: #666666;
}
