.home-screen-page {
    background-color: var(--ws-color-slonovaca);
}

.logo-label {
    font-size: 30px;
    line-height: 39px;
    color: #0D0140;
}

.home-title {
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3rem;
    display: flex;
    flex-flow: column;
}

.home-container {
    margin-left: 28px;
}

.home-description {
    margin-top: 15px;
}

.next-screen {
    display: flex;
    justify-content: flex-end;
    margin-right: 26px;
}

.app-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;
    margin-top: 26px;
    z-index: 1;
    position: relative;
}

.logo-part2 {
    font-weight: bold;
}

.home-teaser {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 280px;
    margin-top: 78px;
}

.home-teaser-background {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
}

.home-teaser-foreground {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.home-description {
    font-size: .875rem;
    line-height: 1.375rem;
}

.home-footer {
    display: block;
    margin-bottom: 40px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.next-screen > button {
    color: white;
    background-color: #130160;
}

.next-button {
    background-color: #cfe3f7 !important;
}

@media (max-height: 760px) {
    .home-title {
        font-size: 1.875rem;
        line-height: 2.125rem;
    }

    .home-teaser {
        margin-top: 3rem;
    }

    .home-footer {
        margin-bottom: 1.25rem;
    }

    .app-logo {
        margin-top: 1.25rem;
    }
}