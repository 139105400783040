thead {
    display: none;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 0px;
    background-color: white;
    border: 0 !important;

}

.fc .fc-daygrid-day.fc-day-today {
    background-color: white !important;
    border: 0 !important;
}

.fc .fc-cell-shaded, .fc .fc-day-disabled {
    background-color: white !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: 0 !important;
}

.fc-v-event {
    padding: .375rem;
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left-width: 10px !important;
}

.fc-event-time {
    font-size: 10px !important;
}

.fc-event-title-container {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}


.fc-timegrid-slot-label-cushion,
.fc-timegrid-axis-cushion {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #817E7E;
}

.fc-timegrid-slots col {
    min-width: 3.75rem !important;
}

.fc-timegrid-col {
    padding-left: 2.5rem;
}

.fc-timegrid-axis-frame {
    min-width: 3.75rem;
}