.onboarding-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
  text-align: center;
}

.onboarding-info {
  font-size: 14px;
  line-height: 160%;
  margin: 32px;
}

.onboarding-info.first-line {
  text-align: center;
  margin-top: 120px;
}

.info-panel-footer-line1 {
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.info-panel-footer-line2 {
  font-size: 12px;
  line-height: 16px;
}

.agreement-description {
  margin-left: 0.25rem;
}
