.calendar-day-collection {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    overflow: auto;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.header-container {
    display: block;
    padding: 16px;
    padding-right: 0;
    background: #F2F2F2;
    border-radius: 0px 0px 0px 20px;
    margin-bottom: 1.5rem;

    position: sticky;
    top: 0;
    z-index: 3;
}

.calendar-page {
    background-color: white;
}

.fc-timegrid-cols col {
    min-width: 60px;
}

.calendar-day-card-action {
    background-color: #ddeffb !important;
    color: #3b5369;
}

.action-button {
    position: fixed;
    z-index: 4;
    bottom: .75rem;
    right: .75rem;
}