.calendar-today-label {
    font-size: 20px;
    line-height: 26px;
    color: #979797;
}

.calendar-today {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 1rem;
}

.calendar-day-card {
    display: flex;

    cursor: pointer;
    width: 4rem;
    height: 5.125rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    zoom: 0.85;
}

.cal-header-day {
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
}

.cal-header-day-short {
    color: #524B6B;
    margin-bottom: .25rem;
}

.calendar-day-selected .cal-header-day-short {
    color: white;
}

.calendar-day-item {
    background: white;
    border: 1px solid #CACACA;
    border-radius: 6px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.calendar-day-collection > div:last-child {
    margin-right: 1.25rem;
}

.calendar-day-today {
    background: #F7F7F7;
}

.calendar-day-selected .calendar-day-item {
    background-color: #FF5C91;
    color: white;
}
